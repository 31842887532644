import React, { useState } from "react";
import CompanyMap from "./CompanyMap";
import callApi from "../../services/apiCaller";
import swal from "sweetalert2";

function Contacts() {
  const [name, setName] = useState("");
  const [sdt, setSdt] = useState("");
  const [email, setEmail] = useState("");
  const [content, setContent] = useState("");

  const submitQuestions = async () => {
    console.log(name);
    console.log(sdt);
    console.log(email);
    console.log(content);
    if (name === "" || name === null) {
      swal.fire({
        text: "Vui lòng nhập họ và tên!",
        icon: "warning",
      });
      return false;
    }
    if (sdt === "" || sdt === null) {
      swal.fire({
        text: "Vui lòng nhập số điện thoại!",
        icon: "warning",
      });
      return false;
    }
    if (email === "" || email === null) {
      swal.fire({
        text: "Vui lòng nhập email!",
        icon: "warning",
      });
      return false;
    }
    if (content === "" || content === null) {
      swal.fire({
        text: "Vui lòng nhập nội dung câu hỏi!",
        icon: "warning",
      });
      return false;
    }
    const body = {
      contact_name: name,
      phone: sdt,
      email: email,
      description: content,
    };
    var apiBaseUrl =
      process.env.REACT_APP_DOMAIN_API + "/gd/v3/h5p/contact_supports";
    await callApi(apiBaseUrl, "POST", body)
      .then(function (response) {
        if (response === false || response === undefined) {
          swal.fire({
            text: "Gửi thông tin liên hệ không thành công. Vui lòng liên hệ theo số hotline 0903030246!",
            icon: "warning",
          });
          return false;
        }
        if (response !== undefined && response.data.data.record !== undefined) {
          swal.fire({
            text: "Cám ơn bạn đã gửi thông tin. Chúng tôi sẽ liên hệ lại trong thời gian sớm nhất!",
            icon: "success",
          });
        } else {
          swal.fire({
            text: "Gửi thông tin liên hệ không thành công. Vui lòng liên hệ theo số hotline 0903030246!",
            icon: "warning",
          });
        }
      })
      .catch(function (error) {
        // config.hideLoader();
        swal.fire({
          text: "Gửi thông tin liên hệ không thành công. Vui lòng liên hệ theo số hotline 0903030246!",
          icon: "warning",
        });
        console.log(error);
      });
  };

  return (
    <div className="flex flex-col min-h-screen bg-sky-50">
      <div className="relative z-10 mt-2 px-5 lg:px-24 mb-10">
        <div className="ct-sub-container-home">
          <div className="flex flex-col md:flex-row gap-2">
            <div className="md:basis-1/2 flex flex-col justify-start items-start text-left md:my-5 text-black rounded-xl">
              <p className="text-lg lg:text-xl 2xl:text-4xl italic font-semibold my-5">
                Vui lòng gửi email cho chúng tôi bất cứ lúc nào. Chúng tôi sẵn
                sàng lắng nghe và hỗ trợ Quý khách.
              </p>
              <div className="ml-5">
                <div>
                  <p className="text-base lg:text-lg 2xl:text-3xl font-bold mb-1 uppercase">
                    Email
                  </p>
                  <p className="text-base lg:text-lg 2xl:text-3xl mb-2">
                    Admin@bachkhoa.tech
                  </p>
                </div>
                <div>
                  <p className="text-base lg:text-lg 2xl:text-3xl font-bold mb-1 uppercase">
                    HOTLINE
                  </p>
                  <p className="text-base lg:text-lg 2xl:text-3xl mb-2">
                    090 303 0246
                  </p>
                </div>
                <div>
                  <p className="text-base lg:text-lg 2xl:text-3xl font-bold mb-1 uppercase">
                    TRỤ SỞ
                  </p>
                  <p className="text-base lg:text-lg 2xl:text-3xl mb-2">
                    Số 3 Công Trường Quốc Tế, Quận 3, Thành phố Hồ Chí Minh
                  </p>
                </div>
              </div>
            </div>
            <div className="md:basis-1/2 my-1 md:my-5 border-b pb-5 md:pb-0 border-b-gray-300 border-r border-r-gray-300 bg-sky-50 rounded-xl">
              <p className="text-center text-base md:xl:text-xl lg:text-xl 2xl:text-4xl lg:m-5">
                Hãy điền thông tin vào bên dưới. <br /> Chúng tôi sẽ liên hệ với
                bạn trong thời gian sớm nhất
              </p>
              <div className="mx-2 lg:mx-10 flex flex-col justify-center items-center pb-2">
                <div className="grid grid-cols-2 gap-5 my-2 w-full">
                  <input
                    id="fullname"
                    name="fullname"
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    placeholder="*Họ và tên"
                    className="w-full h-10 rounded-lg pl-15 pr-25 border border-solid border-stone-300 shadow-md placeholder:text-stone-500 p-2 lg:p-4 focus:outline-none"
                  />
                  <input
                    id="sdt"
                    name="sdt"
                    onChange={(e) => setSdt(e.target.value)}
                    type="number"
                    placeholder="*Số điện thoại"
                    className="w-full h-10 rounded-lg pl-15 pr-25 border border-solid border-stone-300 shadow-md placeholder:text-stone-500 p-2 lg:p-4 focus:outline-none"
                  />
                </div>
                <input
                  id="email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="*Email"
                  className="w-full h-10 rounded-lg pl-15 pr-25 border border-solid border-stone-300 shadow-md placeholder:text-stone-500 my-2 p-2 lg:p-4 focus:outline-none"
                />
                <textarea
                  id="content"
                  name="content"
                  onChange={(e) => setContent(e.target.value)}
                  type="text"
                  placeholder="*Thông tin chi tiết"
                  className="w-full h-40 rounded-lg pl-15 pr-25 border border-solid border-stone-300 shadow-md placeholder:text-stone-500 my-2 p-2 lg:p-4 focus:outline-none"
                />
                <button
                  onClick={() => submitQuestions()}
                  className="w-fit h-10 uppercase rounded-xl bg-[#367AE2] font-bold text-white py-2 px-5"
                >
                  Gửi thông tin
                </button>
              </div>
            </div>
          </div>
          <CompanyMap />
        </div>
      </div>
    </div>
  );
}

export default Contacts;
