import React from 'react';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import {
  Copyright,
  FooterWrapper,
  InfoBlock,
  LogoSection,
  StyledContainer,
  StyledDivider,
  StyledMediaIcon
} from './Styled';

import appleStore from '../../assets/images/Footer/appleStore.png';
import googlePlay from '../../assets/images/Footer/googlePlay.png';
import facebook from '../../assets/images/Footer/facebook.png';
import youtube from '../../assets/images/Footer/youtube.png';
import tiktok from '../../assets/images/Footer/tiktok.png';
import logoBgd from '../../assets/images/Footer/logo-trung-tam-giao-duc-setdc.png';
import logoBachKhoa from '../../assets/images/Footer/logo-khong-nen---color.png';
import logoStb from '../../assets/images/Footer/logo-stb.png';

const Footer = props => {
  const bachKhoaLinks = [
    { text: 'Giới thiệu', url: 'https://bachkhoa.tech/about' },
    { text: 'Tầm nhìn - Sứ mệnh', url: 'https://bachkhoa.tech/about' },
    { text: 'Khách hàng tiêu biểu', url: 'https://bachkhoa.tech/products' },
    { text: 'Tin tức', url: 'https://bachkhoa.tech/news' },
    { text: 'Tuyển dụng', url: 'https://bachkhoa.tech/news' },
    { text: 'Phân phối sản phẩm', url: 'javascript:void(0)', modal: true },
    { text: 'Liên hệ hỗ trợ', url: 'javascript:void(0)', modal: true }
  ];

  const teachingLinks = [
    {
      text: 'Hệ thống Quản lý học tập LMS 360 e-Learning',
      url: 'https://lms360.edu.vn/'
    },
    {
      text: 'Hệ thống Ngân hàng học liệu số dùng chung',
      url: 'https://lms360.edu.vn'
    },
    { text: 'Học liệu số bản quyền', url: 'https://lms360.edu.vn/danh-sach-khoa-hoc-nxb' },
    { text: 'Phòng thí nghiệm mô phỏng', url: 'https://lms360.edu.vn/phong-thi-nghiem-ao' },
    {
      text: 'Chatbot BKTech ứng dụng trí tuệ nhân tạo',
      url: 'https://lms360.edu.vn/danh-sach-khoa-hoc'
    },
    {
      text: 'Hệ thống Kiểm tra đánh giá trực tuyến',
      url: 'https://thi.lms360.edu.vn'
    },
    {
      text: 'Phần mềm tạo học liệu số bằng trí tuệ nhân tạo',
      url: 'https://lms360.edu.vn/danh-sach-khoa-hoc'
    },
    { text: 'Đào tạo nhân lực số', url: 'https://lms360.edu.vn' }
  ];

  const managementLinks = [
    { text: 'Hệ thống Quản lý trường học', url: 'https://qlth.lms360.edu.vn' },
    {
      text: 'Ứng dụng truyền thông nội bộ',
      url: 'https://qlth.lms360.edu.vn/school-admin/announcement?co=41&se=7'
    },
    { text: 'Học bạ số', url: 'https://qlth.lms360.edu.vn/school-admin/ho-so-luu-tru/hoc-ba-so?co=41&se=7&f=%7B%22cId%22%3A41%2C%22sId%22%3A7%2C%22gId%22%3A2031%2C%22clId%22%3A39078%2C%22kw%22%3A%22%22%2C%22sRs%22%3A-1%2C%22schId%22%3A16521%2C%22cD%22%3A%22N%C4%83m+h%E1%BB%8Dc+2024-2025%22%2C%22sD%22%3A2%7D' },
    {
      text: 'Sắp xếp Thời khóa biểu tự động',
      url: 'https://qlth.lms360.edu.vn/school-admin/lich-giang-day/thoi-khoa-bieu?co=41&se=7'
    },
    {
      text: 'Điểm danh thông minh với camera AI',
      url: 'https://qlth.lms360.edu.vn/school-admin/diem-danh-thong-minh/cai-dat-diem-danh?co=41&se=7&f=%7B%22cId%22%3A41%2C%22sId%22%3A7%2C%22gId%22%3A2031%2C%22clId%22%3A39078%2C%22kw%22%3A%22%22%2C%22cD%22%3A%22N%C4%83m+h%E1%BB%8Dc+2024-2025%22%2C%22sD%22%3A2%7D'
    },
    {
      text: 'Hệ thống Kiểm định chất lượng Giáo dục',
      url: 'https://kiemdinh.lms360.edu.vn'
    },
    {
      text: 'Hệ thống Thi đua khen thưởng',
      url: 'https://thiduakhenthuong.hcm.edu.vn'
    },
    { text: 'Hệ thống đánh giá và tự đánh giá', url: 'https://thidua.vn/' }
  ];

  return (
    <FooterWrapper>
      <StyledContainer>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="flex flex-col items-center">
            <LogoSection>
              <a
                className="logo-bgd"
                href="https://vp2moet.edu.vn/"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={logoBgd}
                  alt="Logo Sách TPHCM"
                />
              </a>
              <div className="font-bold text-center">
                TRUNG TÂM PHÁT TRIỂN GDĐT PHÍA NAM <br /> BỘ GIÁO DỤC VÀ ĐÀO TẠO
              </div>
              <div className="text-center">
                ĐỐI TÁC NGHIÊN CỨU, ỨNG DỤNG KHCN VÀ CHUYỂN ĐỔI SỐ
              </div>
            </LogoSection>
          </div>
          <div className="flex flex-col items-center">
            <LogoSection>
              <a
                className='flex items-center justify-center'
                href="https://bachkhoa.tech/"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  className="logo-bachkhoa"
                  src={logoBachKhoa}
                  alt="Logo BKT"
                />
              </a>
              <div className="text-center font-bold">
                TẬP ĐOÀN KHOA HỌC CÔNG NGHỆ BÁCH KHOA
              </div>
              <div className="text-center">
                Địa chỉ:{' '}
                <a
                  href="https://g.co/kgs/6cvFbhb"
                  target="_blank"
                  rel="noopener noreferrer">
                  Số 3 Công Trường Quốc Tế, Quận 3, TPHCM
                </a>
              </div>
              <div className="text-center">
                Điện thoại:{' '}
                <a
                  target="_blank"
                  href="tel:(0287)102 0246"
                  rel="noopener noreferrer">
                  (0287)102 0246 - 090 303 0246
                </a>
              </div>
            </LogoSection>
          </div>
          <div className="flex flex-col items-center">
            <LogoSection>
              <a
                className="logo-sach"
                href="https://www.stb.com.vn/vi/?view=home"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={logoStb}
                  alt="Logo Sách TPHCM"
                />
              </a>
              <div className="font-bold text-center">
                CÔNG TY CỔ PHẦN <br /> SÁCH VÀ THIẾT BỊ TRƯỜNG HỌC TPHCM
              </div>
              <div className="text-center">
                ĐƠN VỊ CUNG CẤP HỌC LIỆU SỐ BẢN QUYỀN
              </div>
            </LogoSection>
          </div>
        </div>
        <StyledDivider />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-8">
          <div>
            <InfoBlock>
              <div className="text-title-2">BACH KHOA TECH GROUP</div>
              <ul>
                {bachKhoaLinks.map((link, index) => (
                  <li key={index}>
                    {link.modal ? (
                      <a
                        href={link.url}
                        data-toggle="modal"
                        flow="down"
                        data-target="#notiModalContact">
                        {link.text}
                      </a>
                    ) : (
                      <a
                        target="_blank"
                        href={link.url}
                        rel="noopener noreferrer">
                        {link.text}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
              <div className="flex items-center mt-6 app-download">
                <div className="flex flex-col items-center justify-center text-dark mr-4">
                  <QRCodeSVG
                    value={window.location.origin + '/app-redirect'}
                    size={72}
                  />
                </div>
                <div className="flex flex-col text-dark">
                  <div>
                    <a
                      className="mb-2"
                      href="https://apps.apple.com/vn/app/lms360/id6475024661?l=vi"
                      target="_blank"
                      rel="noreferrer">
                      <img
                        className='h-10'
                        src={appleStore}
                        alt="logo"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=lms360.edu.vn"
                      className="mb-2"
                      rel="noreferrer">
                      <img
                        className='h-10'
                        src={googlePlay}
                        alt="logo"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </InfoBlock>
          </div>
          <div>
            <InfoBlock>
              <div className="text-title-2">CHUYỂN ĐỔI SỐ TRONG DẠY VÀ HỌC</div>
              <ul>
                {teachingLinks.map((link, index) => (
                  <li key={index}>
                    <a target="_blank" href={link.url}>
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </InfoBlock>
          </div>
          <div className="flex justify-center">
            <InfoBlock>
              <div className="text-title-2">CHUYỂN ĐỔI SỐ TRONG QUẢN LÝ</div>
              <ul>
                {managementLinks.map((link, index) => (
                  <li key={index}>
                    <a target="_blank" href={link.url}>
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </InfoBlock>
          </div>
        </div>
        <StyledDivider />
        <Copyright>
          Copyright ©2021 - Bản quyền thuộc Công Ty Cổ Phần Tập Đoàn Khoa Học
          Công Nghệ Bách Khoa
          <div className="flex justify-center items-center gap-4">
            <a href="https://www.facebook.com/lms360elearning" target="_blank">
              <StyledMediaIcon
                src={facebook}
                alt="Facebook"
              />
            </a>
            <a
              href="https://www.youtube.com/@lms360elearning-pg8ps"
              target="_blank">
              <StyledMediaIcon
                src={youtube}
                alt="Youtube"
              />
            </a>
            <a href="https://www.tiktok.com/@bk.tech360" target="_blank">
              <StyledMediaIcon
                src={tiktok}
                alt="Tiktok"
              />
            </a>
          </div>
        </Copyright>
      </StyledContainer>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;