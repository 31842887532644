import React from "react";

const CompanyMap = () => {
  return (
    <div className="w-full h-auto">
      <iframe
        title="Company map"
        className="w-full h-40 md:h-60 lg:h-80 2xl:h-96 mt-10 lg:mt-4"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.3750733612437!2d106.69253487589489!3d10.782558289366547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f36c09482ab%3A0xe3c1ef746a91160f!2zMyBDw7RuZyB0csaw4budbmcgUXXhu5FjIFThur8sIFBoxrDhu51uZyA2LCBRdeG6rW4gMywgSOG7kyBDaMOtIE1pbmgsIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1742454337329!5m2!1svi!2s"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default CompanyMap;
