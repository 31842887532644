import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import footerBackground from "../../assets/images/Footer/footer-background.png";

// Biến màu chung
export const primaryColor = "#1b2d72";
export const secondaryColor = "#333";

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  background: url(${footerBackground}) no-repeat center center/cover;
  padding: 10px;
  text-align: center;
  color: ${primaryColor};
  position: relative;

  @media (max-width: 1024px) {
    padding: 10px 0;
  }
`;

export const StyledContainer = styled(Container)`
  max-width: 1440px;
  padding: 0px; /* Giữ khoảng cách hai bên */

  a:hover {
    color: #027ac1 !important;
  }
`;

export const StyledRow = styled(Row)`
  margin: 0px !important;
`;

export const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
`;

export const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 0px !important;
  color: ${primaryColor};

  .logo-bgd,
  .logo-sach {
    width: 45%;
    max-width: 128px;
    height: auto;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .logo-bachkhoa {
    width: 80%;
    height: auto;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .logo-text {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  .address-text,
  .phone-text {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    text-align: center;
  }

  .address-text a,
  .phone-text a {
    color: inherit;
    text-decoration: none;
  }

  .phone-text a {
    display: inline-block;
  }

  .logo-part-text {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  .partner-text {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    text-align: center;
  }

  @media (max-width: 1024px) {
    margin-bottom: 10px;
    .logo-text {
      font-size: 15px;
    }
  }
`;

export const Copyright = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: ${primaryColor};
  padding: 5px 32px;

  @media (max-width: 1024px) {
    font-size: 10px;
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }
`;

// Đường kẻ ngang chia các phần
export const StyledDivider = styled.hr`
  border: none;
  height: 1px;
  background-color: #a8b0d0;
  margin: 0;
  opacity: 0.5;

  @media (max-width: 1024px) {
    margin: 15px 0;
  }
`;

export const InfoBlock = styled.div`
  margin: 20px 0;
  .text-title-2 {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 16px;
    color: ${primaryColor};
    text-align: left;
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    font-family: Montserrat;
    font-size: 13px;
    color: ${primaryColor};
    text-align: left;
    cursor: pointer;
    padding: 5px 0;

    a {
      text-decoration: none;
      font-family: Montserrat;
      color: ${primaryColor};
    }
  }

  @media (max-width: 1024px) {
    .text-title-2 {
      font-size: 14px;
      text-align: center;
    }

    li {
      text-align: center;
      font-size: 12px;
      padding: 2px;
    }
    
    .app-download {
      display: flex;
      justify-content: center;
      gap: 5px;
    }
  }
`;

export const StyledMediaIcon = styled.img`
  width: 36px;
  border-radius: 50%;
`;
